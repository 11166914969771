import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column flex-center flex-column-fluid p-10" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  class: "fw-bold mb-10",
  style: {"color":"#a3a3c7"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.getIllustrationsPath('18.png'),
      alt: "",
      class: "mw-100 mb-10 h-lg-450px"
    }, null, 8, _hoisted_2),
    _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.I18nUtils.translate("sea.pages.error404.description")), 1),
    _createVNode(_component_router_link, {
      to: "/dashboard",
      class: "btn btn-primary"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.I18nUtils.translate("sea.pages.error404.back")), 1)
      ]),
      _: 1
    })
  ]))
}